$(document).ready(function() {
  if ($('form[method="POST"]').length > 0 && window.location.search.length > 0) {
    history.pushState({}, '', window.location.origin + window.location.pathname);
  }
});

Mi = typeof Mi !== 'undefined' ? Mi : {};
Mi.Misc = {};

/**
 * Escapes given string.
 * 
 * @param {string} s 
 *   input.
 * @returns {string} 
 *   output.
 */
RegExp.miEscape = (s) => {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

Mi.Misc.Tools = {

    /**
     * Set a cookie.
     * 
     * @param {string} name
     *   Cookie name.
     * @param {mixed} value
     *   Cookie value.
     * @param {int} days
     *   Validity duration (in days).
     * @returns {undefined}
     */
    setCookie: function  (name, value, days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = '; expires=' + date.toGMTString();
        document.cookie = name + '=' + value + expires + ';path=/';
    },

    /**
     * Get a cookie.
     * 
     * @param {any} name
     *   Cookie name.
     * @param {any} [defaultValue]
     *   Default value if cookie is not found. Null if missing.
     * @returns {mixed|null}
     *   Cookie value if defined, default value otherwise. 
     */
    getCookie: function (name, defaultValue) {
        var n = name + '=';
        var cookie = document.cookie.split(';');
        for (var i = 0; i < cookie.length; i++) {
            var c = cookie[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(n) == 0) {
                return c.substring(n.length, c.length);
            }
        }
        return typeof defaultValue !== 'undefined' ? defaultValue : null;
    },

    /**
     * 
     * @param {string} sParam Retrieving parameters from the url
     * @param {number|string} defaultValue Default value, if search parameter is
     *   not found. `''` by default
     * @returns {number|string} Value of sParam
     */
    getUrlParameter: function (sParam, defaultValue) {
        if (typeof defaultValue === 'undefined') {
            defaultValue = '';
        }
        const results = new RegExp('[?&]' + RegExp.miEscape(sParam) + '(?:=([^&#]*)|[#&]|$)').exec(window.location.href);
        return (results !== null && typeof results[1] !== 'undefined') ? decodeURI(results[1]) : defaultValue;
    },
};

Mi.Misc.Gclid = {

    mainLogic: function () {
        if (!TrackingConsentManager.canTrackCurrentUser()) {
            return false;
        }

        const gclid = Mi.Misc.Tools.getUrlParameter('gclid');
        if (gclid) {
            const gclsrc = Mi.Misc.Tools.getUrlParameter('gclsrc');
            if (!gclsrc || gclsrc.indexOf('aw') !== -1) {
                Mi.Misc.Tools.setCookie('gclid', gclid, 90);
            }
        }

        const $inputGclid = $('input[name="gclid"]');
        if ($inputGclid.length > 0) {
            const gclidCookie = Mi.Misc.Tools.getCookie('gclid', null);
            if (gclidCookie !== null) {
                $inputGclid.val(gclidCookie);
            }
        }

        return true;
    }
};

Mi.Misc.Social = {

    /**
     * @returns {undefined}
     */
    mainLogic: function () {
        this.loadSocials();
    },

    /**
     * @returns {string}
     *   Blog page title.
     */
    getDescription: function () {
        return $('meta[property="og:description"]').attr('content').trim().replace(/[%#<>]/g, '');
    },

    /**
     * @returns {string}
     *   Blog page title.
     */
    getImage: function () {
        return $('meta[property="og:image"]').attr('content').replace(/[%#<>]/g, '');
    },

    /**
     * @returns {undefined}
     */
    loadSocials: function () {

        var module = Mi.Misc.Social;

        $('.mi-social-hub-item--facebook').each(function () {
            var $this = $(this);
            var params = {
                u: window.location.href,
                text: Mi.Misc.Social.getDescription(),
                imgUrl: $this.attr('data-image'),
            };
            module.socialClickBehavior($this, 'https://www.facebook.com/sharer/sharer.php', params);
        });

        $('.mi-social-hub-item--twitter').each(function () {
            var params = {
                url: window.location.href,
                text: Mi.Misc.Social.getDescription(),
                via: 'markem-imaje',
            };
            module.socialClickBehavior($(this), 'https://twitter.com/intent/tweet', params);
        });

        $('.mi-social-hub-item--linkedin').each(function () {
            var params = {
                url: window.location.href,
                title: Mi.Misc.Social.getDescription(),
                image: Mi.Misc.Social.getImage()
            };
            module.socialClickBehavior($(this), 'https://www.linkedin.com/shareArticle', params);
        });
    },

    /**
     * 
     * @param {object} $elem
     *   JQuery element to click on.
     * @param {string} url
     *   Share API URL.
     * @param {object} params
     *   Share API parameters like page url, description, images...
     * @returns {undefined}
     */
    socialClickBehavior: function ($elem, url, params) {
        $elem.click(function (e) {
            window.open(url + '?' + $.param(params), 'pop', 'width=600, height=400, scrollbars=no');
            e.preventDefault();
            return false;
        });
    }
}

$(document).ready(function () {
    Mi.Misc.Gclid.mainLogic();
    Mi.Misc.Social.mainLogic();
});